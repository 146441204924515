// extracted by mini-css-extract-plugin
export var dBlock = "notfound-module--d-block--ff2cc";
export var dFlex = "notfound-module--d-flex--e124c";
export var dInline = "notfound-module--d-inline--6c546";
export var dLgBlock = "notfound-module--d-lg-block--f8860";
export var dLgFlex = "notfound-module--d-lg-flex--54ba2";
export var dLgInline = "notfound-module--d-lg-inline--b709d";
export var dLgNone = "notfound-module--d-lg-none--8c545";
export var dMdBlock = "notfound-module--d-md-block--76869";
export var dMdFlex = "notfound-module--d-md-flex--90780";
export var dMdNone = "notfound-module--d-md-none--62c16";
export var dNone = "notfound-module--d-none--580a3";
export var notFound = "notfound-module--notFound--b51a6";
export var notFoundContent = "notfound-module--notFoundContent--f7edd";
export var notFoundDisclaimer = "notfound-module--notFoundDisclaimer--554d6";
export var notFoundHeading = "notfound-module--notFoundHeading--8dd50";
export var notFoundTitle = "notfound-module--notFoundTitle--d4ed1";
export var section = "notfound-module--section--fa426";
export var sectionBig = "notfound-module--sectionBig--9cd99";