import React from "react"
import * as styles from "./notfound.module.scss"

const NotFoundComponent = () => {
  return (
    <div className={styles.notFound}>
      <div className={styles.notFoundContent}>
        <h2 className={styles.notFoundHeading}>Es tut uns leid :(</h2>
        <h4 className={styles.notFoundDisclaimer}>
          Wir konnten die von dir gesuchte Seite nicht finden.
        </h4>
      </div>
    </div>
  )
}

export default NotFoundComponent
